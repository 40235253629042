<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">ข้อมูลคูปอง</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-2">สถานะ</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน" disabled></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>ชื่อคูปอง</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ couponName }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>รหัสใช้คูปอง</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ couponCode }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>ประเภทคูปอง</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ couponType }}</span>
          </v-col>
        </v-row>
        <v-row v-if="couponType === 'ลดตามหมวดหมู่'">
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>ลดตามหมวดหมู่</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ category.category }}</span>
          </v-col>
        </v-row>
        <v-row v-if="couponType === 'ลดตามยี่ห้อ'">
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>ลดตามยี่ห้อ</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ brand.brandName }}</span>
          </v-col>
        </v-row>
        <v-row v-if="couponType === 'ลดเฉพาะสินค้า'">
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>ลดเฉพาะสินค้า</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ product.productName }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>ประเภทาการลดราคา</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ discountType }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>ราคาส่วนลด</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ discountPrice }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>ขั้นต่ำในการใช้</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ minimumDiscount }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>จำนวนคูปอง</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ couponAmt }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>ระยะเวลาของคูปอง</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <v-row align="center" justify="center">
              <v-col cols="12">
                <span>{{ datefrom }}</span>
                <span> - </span>
                <span>{{ dateto }}</span>
              </v-col>
              <!-- <v-col cols="12" md="12" sm="12" xs="12">
                <v-row align="center" justify="center">
                  <v-btn width="80px" class="mt-2 mb-4 mr-4" @click="clear()"
                    >เคลียร์</v-btn
                  >
                  <v-btn
                    width="80px"
                    class="mt-2 mb-4"
                    @click="getForm()"
                    color="#833133"
                    dark
                    >ค้นหา</v-btn
                  >
                </v-row>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span>ลดเฉพาะบริษัทหรือไม่ ?</span>
            <!--  -->
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <v-checkbox
              v-model="eppFlag"
              label="ลดเฉพาะบริษัท"
              disabled
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="eppFlag">
          <v-col cols="12" md="3" sm="3" class="text-left"
            ><span>เลือกบริษัท</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ company.companyName }}</span>
          </v-col>
        </v-row>
        <!-- <v-row align="center" justify="center">
          <v-col cols="12" md="9" sm="9" xs="9">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="10"
                  md="10"
                  sm="10"
                  xs="10"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูปคูปอง</span>
                  
                  <v-img :src="showImage" width="200px"></v-img>
                  <v-text-field
                    v-model="picture_1"
                    @click="changePic1()"
                    :placeholder="imageName1"
                    prepend-icon="mdi-camera"
                    readonly
                    :rules="rules.pic"
                  >
                  </v-text-field
                  ><input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                /></v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row> -->
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <!-- <v-btn @click="submit()" color="primary">บันทึก</v-btn> -->
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      itemsCategory: [],
      itemsType: [],
      itemsBrand: [],
      itemsProduct: [],
      itemsCompany: [],
      id: "",
      couponType: "",
      couponName: "",
      couponCode: "",
      // couponType
      discountType: "",
      discountPrice: null,
      minimumDiscount: null,
      couponAmt: null,
      // couponStart:'',
      // couponEnd:'',
      category: [],
      brand: [],
      product: [],
      company: [],
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      datefrom: new Date().toISOString().substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
      firstDate: new Date("2021-06-01").toISOString().slice(0, 10),
      eppFlag: false,
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    this.getDropdown();
    var Coupondata = JSON.parse(
      Decode.decode(localStorage.getItem("Coupondata"))
    );
    this.id = Coupondata.id;
    console.log("id", this.id);
    this.findOne();
  },
  methods: {
    async findOne() {
      const getdata = await this.axios.get(
        `${process.env.VUE_APP_API}/coupons/` + this.id
      );
      let data = getdata.data.data;
      console.log("data", data);
      this.couponName = data.couponName;
      this.couponCode = data.couponCode;
      this.couponType = data.couponType;
      this.discountType = data.discountType;
      this.discountPrice = data.discountPrice;
      this.minimumDiscount = data.minimumDiscount;
      this.couponAmt = data.couponAmt;
      this.datefrom = data.couponStart;
      this.dateto = data.couponEnd;
      this.eppFlag = data.eppFlag;
      if (data.brandId) {
        this.brand = data.brand;
      }
      if (data.companyId) {
        this.company = data.company;
      }
      if (data.categoryId) {
        this.category = data.category;
      }
      if (data.productId) {
        this.product = data.product;
      }
    },
    async getDropdown() {
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      const typeDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/types`
      );
      const brandDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`
      );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      const productDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/products`
      );
      this.itemsCategory = cateDropdown.data.data;
      this.itemsType = typeDropdown.data.data;
      this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      this.itemsProduct = productDropdown.data.data;
      console.log("itemsProduct", this.itemsProduct);
      //////////get dropdown list/////////////
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageCoupon");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userPoontana"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const data = {
          couponName: this.couponName,
          couponCode: this.couponCode,
          couponType: this.couponType,
          discountType: this.discountType,
          discountPrice: this.discountPrice,
          minimumDiscount: this.minimumDiscount,
          couponAmt: this.couponAmt,
          couponStart: this.datefrom,
          couponEnd: this.dateto,
          eppFlag: this.eppFlag,
          categoryId: this.category || null,
          brandId: this.brand || null,
          productId: this.product || null,
          companyId: this.company || null,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/coupons/` + this.id,
          data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขคูปองส่วนลดสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageCoupon");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขคูปองส่วนลดไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>