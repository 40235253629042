<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">แก้ไขข้อมูลสินค้า</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-4">สถานะ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="3" sm="3"
            ><span>เลือกหมวดหมู่ของสินค้า</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-autocomplete
              v-model="category"
              :items="itemsCategory"
              item-text="category"
              item-value="id"
              placeholder="เลือกหมวดหมู่สินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-autocomplete
          ></v-col>
          <v-col cols="12" md="3" sm="3"
            ><span>เลือกประเภทของสินค้า</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-autocomplete
              v-model="type"
              :items="itemsType"
              item-text="type"
              item-value="id"
              placeholder="เลือกประเภทสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span>เลือกยี่ห้อของสินค้า</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-autocomplete
              v-model="brand"
              :items="itemsBrand"
              item-text="brandName"
              item-value="id"
              placeholder="เลือกยี่ห้อสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span>รหัสสินค้า</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="sku"
              placeholder="รหัสสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span>ชื่อสินค้า</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="productName"
              placeholder="ชื่อสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>รายละเอียดแบบย่อ</span>
            <!-- <span style="color: red">*</span> -->
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-textarea
              v-model="shortDesc"
              placeholder="รายละเอียด"
              solo
              dense
              :rules="rules.required"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>รายละเอียด</span>
            <!-- <span style="color: red">*</span> -->
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <quill-editor
              :content="description"
              :options="editorOption"
              @change="onEditorChange($event)"
            />
          </v-col>
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>เงื่อนไขการผ่อน</span>
            <!-- <span style="color: red">*</span> -->
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-textarea
              v-model="termDesc"
              placeholder="รายละเอียด"
              solo
              dense
            ></v-textarea>
            <!-- <quill-editor
              :content="termDesc"
              :options="editorOption"
              @change="onEditorChangetermDesc($event)"
            /> -->
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span>จำนวนสินค้า</span><span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="stockAmt"
              placeholder="จำนวนสต็อคสินค้า"
              solo
              dense
              :rules="rules.required"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span>หน่วยสินค้า</span><span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="unit"
              placeholder="หน่วย (ชิ้น, เครื่อง)"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="2"
            ><span>ราคาขาย</span><span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="4" sm="4"
            ><v-text-field
              v-model="price"
              placeholder="ราคาขาย"
              solo
              dense
              :rules="rules.required"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="2"
            ><span>ราคาต้นทุน</span><span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="4" sm="4"
            ><v-text-field
              v-model="cost"
              placeholder="ราคาต้นทุน"
              solo
              dense
              :rules="rules.required"
              type="number"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="2" sm="2"
            ><span>ค่าจัดส่ง</span><span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              v-model="deliveryFee"
              placeholder="ค่าจัดส่ง"
              solo
              dense
              :rules="rules.required"
              type="number"
            ></v-text-field
          ></v-col>

          <v-col cols="12" md="2" sm="2"
            ><span>ค่าติดตั้ง</span><span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="4" sm="4"
            ><v-text-field
              v-model="setupFee"
              placeholder="ค่าติดตั้ง"
              solo
              dense
              :rules="rules.required"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="2"><span>แสดงในหน้าหลัก</span> </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-checkbox v-model="isHilight"> </v-checkbox>
          </v-col>
          <v-col cols="12" md="2" sm="2" class="pt-0"
            ><span>โชว์ลำดับที่</span>
          </v-col>
          <v-col cols="12" md="4" sm="4"
            ><v-text-field
              v-model="showIndex"
              placeholder="โชว์ลำดับที่"
              solo
              dense
              :disabled="!isHilight"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2" sm="2"><span>สามารถผ่อนได้หรือไม่?</span> </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-checkbox v-model="isInstallment"> </v-checkbox>
          </v-col>
          <v-col cols="12" md="2" sm="2"><span></span> </v-col>
          <v-col cols="12" md="4" sm="4">
            <!-- <v-checkbox v-model="isInstallment"> </v-checkbox> -->
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <h3 style="font-weight: 800; color: #e53935">เพิ่มตัวเลือก</h3>
            <v-card outlined class="px-2">
              <v-row
                v-for="(n, index) in productOptionList"
                :key="index"
                align="center"
                class="py-0 my-0"
              >
                <v-col cols="12" md="6" sm="12"
                  ><span>เพิ่มตัวเลือก</span>
                  <span style="color: red">*</span>
                  <v-text-field
                    v-model="productOptionList[index].optionName"
                    placeholder="สีน้ำเงิน"
                    solo
                    dense
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-col cols="12" md="12" class="my-2">
                <v-row align="center" justify="end">
                  <v-btn
                    v-if="productOptionList.length > 0"
                    @click="deleteproductOptionList()"
                    dense
                    color="red"
                    class="mr-4"
                    dark
                    rounded
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-btn
                    @click="addproductOptionList()"
                    dense
                    color="green"
                    dark
                    rounded
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" sm="12" xs="12">
            <h3 style="font-weight: 800; color: #e53935">ราคา EPP</h3>
            <v-card outlined class="px-2">
              <v-card outlined style="padding: 20px; margin: 20px;"  v-for="(n, index) in productPriceList"
                :key="index">
              <v-row
               
                align="center"
                class="py-0 my-0"
              >
                <v-col cols="12" md="6" sm="12"
                  ><span>เลือกบริษัท</span>
                  <span style="color: red">*</span>
                  <v-autocomplete
                    v-model="productPriceList[index].companyId"
                    :items="itemsCompany"
                    item-text="companyName"
                    item-value="id"
                    placeholder="เลือกบริษัท"
                    solo
                    dense
                    :rules="rules.required"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" md="6" sm="12"
                  ><span>ราคาบริษัท</span>
                  <span style="color: red">*</span>
                  <v-text-field
                    v-model="productPriceList[index].price"
                    placeholder="ราคา"
                    solo
                    dense
                    :rules="rules.required"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" class="ma-2">
                <v-row align="center" justify="end">
                  <v-btn
                    v-if="productPriceList.length > 0"
                    @click="deleteEppPrice(index)"
                    dense
                    color="red"
                    class="mr-4"
                    dark
                    rounded
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
              </v-row>
              </v-card>
              <v-col cols="12" md="12" class="ma-2">
                <v-row align="center" justify="end" style="margin: 20px;">
                  <!-- <v-btn
                    v-if="productPriceList.length > 0"
                    @click="deleteRow1()"
                    dense
                    color="red"
                    class="mr-4"
                    dark
                    rounded
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn> -->
                  <v-btn @click="addRow1()" dense color="green" dark rounded>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" sm="6" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col cols="11" class="mt-5 ma-10" id="step-3">
                  <span>รูปสินค้าหลัก</span>
                  <span style="color: red">*</span>
                  <v-img :src="showImage" width="200px"></v-img>
                  <v-text-field
                    v-model="picture_1"
                    @click="changePic1()"
                    :placeholder="imageName1"
                    prepend-icon="mdi-camera"
                    readonly
                  >
                  </v-text-field
                  ><input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                /></v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" class="mt-5">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col cols="11" class="mt-5 ma-10" id="step-3">
                  <span>รูปภาพสินค้าเพิ่มเติม</span>
                </v-col>
                <v-col cols="12" class="mb-5"><v-divider></v-divider></v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="pl-3 mb-5"
                  @click="onPickFile()"
                >
                  <v-row no-gutters align="center" justify="center">
                    <v-file-input
                      v-model="DataImage"
                      :items="DataImage"
                      accept="image/png"
                      @change="UploadImage()"
                      id="file_input"
                      multiple
                      :clearable="false"
                      style="display: none"
                    ></v-file-input>
                    <v-img
                      max-width="50"
                      src="@/assets/upload.png"
                      class="mr-3"
                    ></v-img>
                    <span>เลือกรูปภาพ</span>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  v-if="product_image.length !== 0"
                  class="mb-5"
                >
                  <draggable
                    v-model="product_image"
                    :move="onMove"
                    @start="drag = true"
                    @end="drag = false"
                    class="pl-5 pr-5 row fill-height align-center sortable-list"
                  >
                    <v-col
                      v-for="(item, index) in product_image"
                      :key="index"
                      cols="3"
                      md="3"
                    >
                      <v-card dense light class="pa-1">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-icon small light @click="RemoveImage(index, item)"
                            >mdi-close</v-icon
                          >
                        </v-card-actions>
                        <!-- <img width="100%"  :src="item.url" v-if="MethodProduct === 'Create'" />
                              <img width="100%" :src="`${PathImage}${item.url}`" v-else /> -->
                        <v-img
                          :src="item.productImgUrl"
                          aspect-ratio="1.8"
                          contain
                        ></v-img>
                        <v-card-text class="text-md-center">
                          <!-- <span class="subheading">{{item.name|truncate(20, '...') }}</span> -->
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </draggable>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-row class="ma-5" align="center" justify="end">
            <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
            <v-btn @click="submit()" color="primary">บันทึก</v-btn>
          </v-row>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      id: "",
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      company: [],
      itemsCompany: [],
      sku: "",
      productName: "",
      shortDesc: "",
      termDesc: "",
      description: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      unit: "",
      price: "",
      img: [],
      DataImage: [],
      product_image: [],
      productImgUrl: [],
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      activeFlag: true,
      stockAmt: "",
      cost: "",
      deliveryFee: "",
      setupFee: "",
      isHilight: false,
      isInstallment: false,
      showIndex: null,
      companyName: [],
      // listDescription: [],
      productPriceList: [],
      productOptionList: [],

      // approveFlag: "",
      // itemsapproveFlag: [
      //   { text: "ยังไม่อนุมัติ", value: "0" },
      //   { text: "อนุมัติ", value: "1" },
      //   { text: "ตีกลับ", value: "2" },
      // ],
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  async created() {
    var productdata = JSON.parse(
      Decode.decode(localStorage.getItem("productdata"))
    );
    console.log("productdata", productdata);
    this.id = productdata.id;
    this.getOne();
    // this.itemsCategory = cateDropdown.data.data;
    // this.itemsType = typeDropdown.data.data;
    // console.log(this.itemsCategory, this.itemsType);
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill;
    },
    contentCode() {
      return hljs.highlightAuto(this.description).value;
      // return hljs.highlightAuto(this.termDesc).value;
    },
  },
  onFocusOut: function (e) {
    this.description = e.target.innerHTML;
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.description = html;
    },
    onEditorChangetermDesc({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.termDesc = html;
    },
    async addproductOptionList() {
      this.productOptionList.push({
        optionName: this.optionName,
      });
    },
    deleteproductOptionList() {
      if (this.productOptionList.length > 0) {
        this.productOptionList.pop();
      }
    },
    async addRow1() {
      this.productPriceList.push({
        price: null,
        companyId: this.companyId,
      });
      console.log("company", this.productPriceList);
    },
    deleteRow1() {
      if (this.productPriceList.length > 0) {
        this.productPriceList.pop();
      }
    },
    deleteEppPrice(index){
      this.productPriceList.splice(index, 1)
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    UploadImage() {
      for (let i = 0; i < this.DataImage.length; i++) {
        const element = this.DataImage[i];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          var resultReader = reader.result;
          this.productImgUrl = URL.createObjectURL(element);
          this.product_image.push({
            image_data: resultReader,
            productImgUrl: this.productImgUrl,
            name: this.DataImage[i].name,
            id: "-1",
          });
        };
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext;
      const draggedElement = draggedContext;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    RemoveImage(index, item) {
      console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.product_image.splice(index, 1);
    },
    async getOne() {
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      const typeDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/types`
      );
      const brandDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`
      );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      this.itemsCategory = cateDropdown.data.data;
      this.itemsType = typeDropdown.data.data;
      this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      console.log("itemsBrand", this.itemsBrand);
      //////////get dropdown list/////////////
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/` + this.id
      );
      console.log("Product", response);
      const Product = response.data.data;
      this.id = Product.id;
      this.activeFlag = Product.activeFlag;
      this.category = Product.categoryId;
      this.type = Product.typeId;
      this.brand = Product.brandId;
      // this.company = Product.company;
      this.sku = Product.sku;
      this.productName = Product.productName;
      this.description = Product.description;
      this.shortDesc = Product.shortDesc;
      this.termDesc = Product.termDesc;
      this.unit = Product.unit;
      this.price = Product.price;
      // this.img = Product.img;
      this.cost = Product.cost;
      this.stockAmt = Product.stockAmt;
      this.deliveryFee = Product.deliveryFee.toString();
      this.setupFee = Product.setupFee;
      this.isHilight = Product.isHilight;
      this.showIndex = Product.showIndex;
      // this.companyName = Product.productPriceList.company;
      this.productPriceList = Product.productPriceList;
      this.productOptionList = Product.productOptionList;
      this.showImage = Product.imgUrl;
      this.product_image = Product.productImgs;
      this.productImgUrl = Product.productImgs.productImgUrl;

      this.isInstallment = Product.isInstallment;

      // console.log("companyNamecompanyName", this.companyName);
    },

    cancel() {
      this.$router.push("ManageProduct");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const productData = {
          categoryId: this.category,
          typeId: this.type,
          brandId: this.brand,
          sku: this.sku,
          productName: this.productName,
          description: this.description,
          shortDesc: this.shortDesc,
          termDesc: this.termDesc,
          unit: this.unit,
          price: this.price,
          activeFlag: this.activeFlag,
          cost: this.cost,
          stockAmt: this.stockAmt,
          deliveryFee: this.deliveryFee,
          setupFee: this.setupFee,
          isHilight: this.isHilight,
          showIndex: this.showIndex,
          imgUrl: this.pic1,
          imgUrlDesc: this.product_image,
          productPriceList: this.productPriceList,
          productOptionList: this.productOptionList,
          isInstallment: this.isInstallment
        };
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userPoontana"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        console.log("data", productData);
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/products/` + this.id,
          productData,
          auth
        );
        console.log("response product", response);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขข้อมูลสินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageProduct");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขข้อมูลสินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>