<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>ยอดขายรวม ของ SCB</h3>
      </v-col>

      <!-- <v-tabs>
        <v-tab v-for="item in tabitems" :key="item.key" @click="tab(item)">{{
          item.text
        }}</v-tab>
      </v-tabs> -->
      <!-- <v-col cols="12" md="7" sm="7">
        <v-row align="center" justify="center">
          <v-col cols="6">
            <v-menu
              ref="menufrom"
              v-model="menufrom"
              :close-on-content-click="false"
              :return-value.sync="datefrom"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datefrom"
                  label="วันที่เริ่ม"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datefrom"
                no-title
                scrollable
                :min="firstDate"
              >
                <v-btn text color="primary" @click="menufrom = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menufrom.save(datefrom)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="menuto"
              v-model="menuto"
              :close-on-content-click="false"
              :return-value.sync="dateto"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateto"
                  label="วันที่สิ้นสุด"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateto" no-title scrollable>
                <v-btn text color="primary" @click="menuto = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menuto.save(dateto)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" sm="5" xs="12">
        <v-row align="center" justify="center">
          <v-btn width="80px" class="mt-2 mb-4 mr-4" @click="clear()"
            >เคลียร์</v-btn
          >
          <v-btn
            width="80px"
            class="mt-2 mb-4"
            @click="getForm()"
            color="#833133"
            dark
            >ค้นหา</v-btn
          >
        </v-row>
      </v-col> -->
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <ExportReport2SCB :data="items" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersOrder"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.orderdate`]="{ item }">
              {{ new Date(item.orderdate).toLocaleDateString() }}
            </template>
            <!-- <template v-slot:[`item.orderType`]="{ item }">
              <v-col v-if="item.orderType = 'PICKUP'">รับเองที่ร้าน</v-col>
            </template> -->
            <template v-slot:[`item.orderStatus`]="{ item }">
              <v-chip
                v-if="item.orderStatus == 'WAITING_PAYMENT'"
                color="blue"
                dark
                >รอชำระเงิน</v-chip
              >
              <v-chip v-if="item.orderStatus == 'WAITING'" color="yellow"
                >WAITING</v-chip
              >
              <v-chip v-if="item.orderStatus == 'SUCCESS'" color="green" dark
                >ชำระเงินสำเร็จ</v-chip
              >
              <v-chip v-if="item.orderStatus == 'CANCEL'" color="red" dark
                >ยกเลิก</v-chip
              >
              <v-chip v-if="item.orderStatus == 'DERIVERED'">ส่งแล้ว</v-chip>
              <v-chip v-if="item.orderStatus == 'PAID'">PAID</v-chip>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="viewOrder(item)">mdi-eye</v-icon> -->
                <v-icon @click="UpdateOrder(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteOrder(item)">mdi-delete</v-icon>
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
import ExportReport2SCB from "@/views/ExportExcel/ExportReport2SCB.vue";
export default {
  components: {
    ExportReport2SCB,
  },
  data() {
    return {
      order_Date: "16/09/2021", //วันที่สั่งซื้อ
      device_Name: "Huawei Band 4", // รุ่น
      device_Colour: "Pink", // สี
      order_Id: "HEC000000415", // หมายเลขสั่งซื้อ
      first_Name: "fyf", // ชื่อจริง
      last_Name: "tdyd", // นามสกุล
      address_Line1: "fyyf", // ที่อยู่ 1
      address_Line2: "", // ที่อยู่ 2
      address_Line3: "", // ที่อยู่ 3
      address_Line4: "", // ที่อยู่ 4
      zipc_Code: "48150", //รหัสไปรษณีย์
      phone_No: "+919555656454", // เบอร์โทรศัพท์
      email_id: "uggug@gugi.vugu", // email
      headersOrder: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "วันที่สั่งซื้อ", value: "order_Date", align: "center" },
        { text: "รุ่น", value: "device_Name", align: "center" },
        { text: "สี", value: "device_Colour", align: "center" },
        { text: "หมายเลขสั่งซื้อ", value: "order_Id", align: "center" },
        { text: "ชื่อจริง", value: "first_Name", align: "center" },
        { text: "นามสกุล", value: "last_Name", align: "center" },
        { text: "เบอร์โทรศัพท์", value: "phone_No", align: "center" },
        { text: "อีเมล", value: "email_id", align: "center" },
        // { text: "สถานะ", value: "orderStatus", align: "center" },
        // { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      datefrom: new Date().toISOString().substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
      firstDate: new Date("2021-06-01").toISOString().slice(0, 10),
      items: [],
      tabitems: [
        { text: "ทั้งหมด", key: "" },
        // { text: "ยังไม่จ่าย", key: "ยังไม่จ่าย" },
        { text: "รอจัดส่ง", key: "WAITING" },
        { text: "จัดส่งแล้ว", key: "DERIVERED" },
        { text: "ชำระเงินแล้ว", key: "PAID" },
        { text: "ยกเลิก", key: "CANCEL" },
      ],
      search: "",
      count: 0,
    };
  },
  created() {
    this.getAllOrder();
    // setInterval(() => {
    //   this.getAllOrder();
    // }, 10000);
  },
  methods: {
    async tab(val) {
      console.log(val.key);
      // /orders?status=รอการยืนยัน
      if (val.key == "ทั้งหมด") {
        this.getAllOrder();
      } else {
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/orders?orderStatus=` + val.key
        );
        this.items = response.data.data;
        for (let i in this.items) {
          this.items[i].count = parseInt(i) + 1;
        }
        console.log("response", response.data.data);
      }
    },
    async getAllOrder() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/files/getScbTrx`
      );
      // const response = await this.axios.get(
      //   `${process.env.VUE_APP_API}/orders`
      // );
      console.log("getAllOrder", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    UpdateOrder(val) {
      localStorage.setItem("orderdata", Encode.encode(val));
      this.$router.push("EditOrder");
    },
    async DeleteOrder(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userPoontana"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/orders/` + val.id,
            auth
          );
          console.log(response);
          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllOrder();
        }
      });
    },
    goToCreate() {
      this.$router.push("createcategory");
    },
  },
};
</script>