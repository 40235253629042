<template>
  <div>
    <ManageCoupon />
  </div>
</template>
<script>
import ManageCoupon from "@/components/Coupon/ManageCoupon";
export default {
  components: {
    ManageCoupon,
  },
  created() {
  },
};
</script>