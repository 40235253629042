<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">แก้ไขข่าว</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ชื่อหัวข้อ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="title"
              label="ชื่อหัวข้อ"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">รายละเอียดข่าว</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-textarea
              v-model="categoryDescription"
              solo
              dense
              :rules="rules.required"
            ></v-textarea>
          </v-col>
        </v-row> -->
        <v-row align="center" justify="center">
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="10"
                  md="10"
                  sm="10"
                  xs="10"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูปข่าว</span>
                  <span style="color: red">*</span>
                  <v-img :src="showImage" width="200px"></v-img>
                  <v-text-field
                    v-model="picture_1"
                    @click="changePic1()"
                    :placeholder="imageName1"
                    prepend-icon="mdi-camera"
                    readonly
                    :rules="rules.pic"
                  >
                  </v-text-field
                  ><input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                /></v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="12" sm="12">
            <!-- <quill-editor
            ref="myQuillEditor"
            v-model="content"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          /> -->
            <!-- Or manually control the data synchronization -->
            <quill-editor
              :content="content"
              :options="editorOption"
              @change="onEditorChange($event)"
            />
          </v-col>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      categoryname: "",
      categoryDescription: "",
      activeFlag: false,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'font': [] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              ["link", 
            // "image", 
            // "video"
            ],
            ],
            syntax: {
              highlight: text => hljs.highlightAuto(text).value
            }
          }
        },
    };
  },
  created() {
    var Blogdata = JSON.parse(Decode.decode(localStorage.getItem("Blogdata")));
    console.log("Blogdata", Blogdata);
    this.id = Blogdata.id;
    this.title = Blogdata.title;
    this.content = Blogdata.description;
    this.activeFlag = Blogdata.activeFlag;
    this.showImage = Blogdata.blogImg;
    this.pic1 = Blogdata.blogImg;
    this.picture_1 = Blogdata.blogImg;
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageNews");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const data = {
          title: this.title,
          // description: this.brandDescription,
          // activeFlag: this.activeFlag,
          description: this.content,
          blogImg: this.pic1,
        };
        if (this.activeFlag == true) {
          data.activeFlag = "1";
        } else if (this.activeFlag == false) {
          data.activeFlag = "0";
        }
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userPoontana"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/blogs/` + this.id,
          data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขข่าวสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageNews");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขข่าวไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>