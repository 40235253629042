<template>
  <div>
    <ManageVoucher />
  </div>
</template>
<script>
import ManageVoucher from "@/components/Voucher/ManageVoucher";
export default {
  components: {
    ManageVoucher,
  },
  created() {
  },
};
</script>