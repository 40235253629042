<template>
  <v-row align="center" justify="center" class="pa-4">
    <loading :active.sync="loading"></loading>
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">เพิ่มแบนเนอร์</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <!-- <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row> -->
        <!-- <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อแบนเนอร์</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="bannerName"
              label="ชื่อแบนเนอร์"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">รายละเอียด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="bannerDescription"
              label="รายละเอียด"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row> -->
        <v-row align="center" justify="center">
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ลิงค์</span>
            </v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="linkUrl"
              label="ลิงค์"
              solo
              dense
            ></v-text-field>
          </v-col>
          <v-card outlined width="100%">
            <v-row>
              <v-col cols="12" md="4" sm="4">
                <v-col cols="12" md="12" sm="12"
                  ><span>เลือกหมวดหมู่ของสินค้า</span>
                  <span style="color: red">*</span>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-autocomplete
                    v-model="category"
                    :items="itemsCategory"
                    item-text="category"
                    item-value="id"
                    placeholder="เลือกหมวดหมู่สินค้า"
                    outlined
                    dense
                  ></v-autocomplete
                ></v-col>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-col cols="12" md="12" sm="12"
                  ><span>เลือกประเภทของสินค้า</span>
                  <span style="color: red">*</span>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-autocomplete
                    v-model="type"
                    :items="itemsType"
                    item-text="type"
                    item-value="id"
                    placeholder="เลือกประเภทสินค้า"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <v-col cols="12" md="12" sm="12"
                  ><span>เลือกยี่ห้อของสินค้า</span>
                  <span style="color: red">*</span>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-autocomplete
                    v-model="brand"
                    :items="itemsBrand"
                    item-text="brandName"
                    item-value="id"
                    placeholder="เลือกยี่ห้อสินค้า"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-col>

              <v-col cols="12" align="end">
                <v-btn class="ma-4" @click="clearProduct()">เคลียร์</v-btn>
                <v-btn class="ma-4" color="primary" @click="getAllProduct()"
                  >ค้นหา</v-btn
                >
              </v-col>

              <v-col cols="12" style="text-align: -webkit-center">
                <v-img :src="img" width="200px"></v-img>
              </v-col>

              <v-col cols="12" md="3" sm="3"
                ><span class="pt-2 pl-4">ชื่อสินค้า</span>
              </v-col>

              <v-col cols="12" md="7" sm="7">
                <v-autocomplete
                  v-model="productId"
                  :items="items"
                  item-text="productName"
                  item-value="id"
                  outlined
                  dense
                  @change="showImg(productId)"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card>

          <v-col cols="12" md="12" sm="12" xs="12">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="10"
                  md="10"
                  sm="10"
                  xs="10"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูปแบนเนอร์</span>
                  <span style="color: red">*</span>
                  <br />
                  <span style="color: red">ใช้นามสกุล .png ขนาด 300*300 </span>
                  <v-img :src="showImage" width="200px"></v-img>
                  <v-text-field
                    v-model="picture_1"
                    @click="changePic1()"
                    :placeholder="imageName1"
                    prepend-icon="mdi-camera"
                    readonly
                    :rules="rules.pic"
                  >
                  </v-text-field
                  ><input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                /></v-col>
                <!-- <v-col cols="12"
                  ><v-btn @click="submitfileMain()" class="align-right ma-4"
                    >Upload</v-btn
                  ></v-col
                > -->
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      bannerName: "",
      bannerDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      img: "",
      loading: false,
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  async created() {
    await this.dropdown();
    await this.getAllProduct();
  },
  methods: {
    clearProduct() {
      this.items = [];
      this.img = "";
      this.productId = 0;
      this.category = "";
      this.type = "";
      this.brand = "";
      this.dropdown();
      this.getAllProduct();
    },
    async showImg(val) {
      this.loading = true;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/` + val
      );
      console.log("getAllproducts", response.data.data);
      this.img = response.data.data.imgUrl;
      this.loading = false;
    },
    async dropdown() {
      this.loading = true;
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      const typeDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/types`
      );
      const brandDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`
      );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      this.itemsCategory = cateDropdown.data.data;
      this.itemsType = typeDropdown.data.data;
      this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      console.log("companyDropdown", this.itemsCompany);
      this.loading = false;
    },
    async getAllProduct() {
      this.loading = true;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/findAllAdmin?brandId=` +
          this.brand +
          "&typeId=" +
          this.type +
          "&categoryId=" +
          this.category
        // 'http://localhost:8081/types/'
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      this.loading = false;
    },
    async submitfileMain() {
      // let formData = new FormData();
      // formData.append("files", this.pic1);
      // formData.append("type", this.typeImg);
      // console.log("formData", formData);
      // const auth = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // };
      const fileData = {
        file: this.pic1,
      };
      this.uploaded = true;
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/files/base64`,
        fileData
      );
      // .then(function () {
      console.log("SUCCESS!!");
      console.log("response", response);
      this.uploaded = false;
      if (response.data.response_status == "SUCCESS") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "อัพโหลดสำเร็จ",
        });
        this.pic1URL = response.data.data.path;
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "อัพโหลดไฟล์ไม่สำเร็จ",
        });
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageBanners");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        await this.submitfileMain();
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userPoontana"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          imgUrl: this.pic1URL,
          linkUrl: this.linkUrl,
          productId: this.productId,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/banners`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างแบนเนอร์สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageBanners");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>