<template>
  <div>
    <ManageBrand />
  </div>
</template>
<script>
import ManageBrand from "@/components/Brand/ManageBrand";
export default {
  components: {
    ManageBrand,
  },
  created() {
  },
};
</script>
