<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">แก้ไขหมวดหมู่</h1>
    </v-col>

    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ชื่อหมวดหมู่</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="categoryname"
              label="ชื่อหมวดหมู่"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">รายละเอียด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="categoryDescription"
              label="รายละเอียด"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col cols="12" md="12" sm="6" xs="12">
          <v-card outlined>
            <v-row no-gutters align="center" justify="center">
              <v-col cols="11" class="mt-5 ma-10" id="step-3">
                <span>รูปแบรนด์สินค้า</span>
                <span style="color: red">*</span>
                <br />
                <span style="color: red">ใช้นามสกุล .png ขนาด 300*300 </span>
                <v-row align="center" justify="center">
                  <v-img
                    v-model="picture_1"
                    @click="changePic1()"
                    src="@/assets/upload.png"
                    class="mr-3"
                    max-width="50"
                  >
                  </v-img>
                  <span>เลือกรูปภาพ</span>
                </v-row>
                <v-col
                  cols="3"
                  md="3"
                  class="pl-5 pr-5 row fill-height align-center sortable-list"
                >
                  <v-card dense light class="pa-1" v-if="showImage">
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-icon small light @click="RemoveImage(index, item)"
                        >mdi-close</v-icon
                      >
                    </v-card-actions>

                    <v-img
                      :src="showImage"
                      width="200px"
                      aspect-ratio="1.8"
                      contain
                    ></v-img>
                  </v-card>
                </v-col>
                <input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="image/png"
                  @change="showPicture1($event)"
                  style="display: none"
                  hidden
              /></v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-row align="center" justify="end" class="pt-4">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      categoryname: "",
      categoryDescription: "",
      activeFlag: false,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    var Catedata = JSON.parse(Decode.decode(localStorage.getItem("Catedata")));
    console.log("Catedata", Catedata);
    this.id = Catedata.id;
    this.categoryname = Catedata.category;
    this.categoryDescription = Catedata.description;
    this.activeFlag = Catedata.activeFlag;
    this.showImage = Catedata.categoryImg;
    this.pic1 = Catedata.categoryImg;
    this.picture_1 = Catedata.categoryImg;
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageCategory");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const data = {
          id: this.id,
          category: this.categoryname,
          description: this.categoryDescription,
          activeFlag: this.activeFlag,
          categoryImg: this.pic1,
        };
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userPoontana"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/categories/` + this.id,
          data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างหมวดหมู่สินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageCategory");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างหมวดหมู่สินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>