<template>
  <div>
    <ManageCompany />
  </div>
</template>
<script>
import ManageCompany from "@/components/Company/ManageCompany";
export default {
  components: {
    ManageCompany,
  },
  created() {
  },
};
</script>
