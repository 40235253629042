import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '@/views/Homepage.vue'
import login from '../views/Login.vue'
import forgotPassword from '@/views/forgotPassword.vue'
import forgotPasswordSuccess from '@/views/forgotPasswordSuccess.vue'


Vue.use(VueRouter)

const routes = [{

    path: '/',
    name: 'login',
    component: login
},
{ path: '/forgotpassword', name: 'forgot', component: forgotPassword },
{ path: '/forgotPasswordSuccess', name: 'forgotSuccess', component: forgotPasswordSuccess },
{
    path: '/ExportVat',
    name: 'ExportVat',
    component: () =>
        import('@/views/ExportExcel/ExportVat.vue')
},
{
    path: '/invc',
    name: 'invc',
    component: () =>
        import('@/views/ExportExcel/ExportInvoiceeee.vue')
},
{
    path: '/Home',
    component: Homepage,
    children: [{
        path: '/ManageCategory',
        name: 'ManageCategory',
        component: () =>
            import('@/views/ManageCategory')
    },
    {
        path: '/CreateCategory',
        name: 'CreateCategory',
        component: () =>
            import('@/components/Category/CreateCategory.vue')
    },
    {
        path: '/EditCategory',
        name: 'EditCategory',
        component: () =>
            import('@/components/Category/EditCategory.vue')
    },
    {
        path: '/ViewCategory',
        name: 'ViewCategory',
        component: () =>
            import('@/components/Category/ViewCategory.vue')
    },
    {
        path: '/ManageType',
        name: 'ManageType',
        component: () =>
            import('@/views/ManageType')
    },
    {
        path: '/CreateType',
        name: 'CreateType',
        component: () =>
            import('@/components/Type/CreateType.vue')
    },
    {
        path: '/EditType',
        name: 'EditType',
        component: () =>
            import('@/components/Type/EditType.vue')
    },
    {
        path: '/ViewType',
        name: 'ViewType',
        component: () =>
            import('@/components/Type/ViewType.vue')
    },
    {
        path: '/ManageBrand',
        name: 'ManageBrand',
        component: () =>
            import('@/views/ManageBrand')
    },
    {
        path: '/CreateBrand',
        name: 'CreateBrand',
        component: () =>
            import('@/components/Brand/CreateBrand.vue')
    },
    {
        path: '/EditBrand',
        name: 'EditBrand',
        component: () =>
            import('@/components/Brand/EditBrand.vue')
    },
    {
        path: '/ViewBrand',
        name: 'ViewBrand',
        component: () =>
            import('@/components/Brand/ViewBrand.vue')
    },
    {
        path: '/ManageBanners',
        name: 'ManageBanners',
        component: () =>
            import('@/views/ManageBanners')
    },
    {
        path: '/CreateBanners',
        name: 'CreateBanners',
        component: () =>
            import('@/components/Banners/CreateBanners.vue')
    },
    {
        path: '/EditBanners',
        name: 'EditBanners',
        component: () =>
            import('@/components/Banners/EditBanners.vue')
    },
    {
        path: '/ViewBanners',
        name: 'ViewBanners',
        component: () =>
            import('@/components/Banners/ViewBanners.vue')
    },
    {
        path: '/CreateProduct',
        name: 'CreateProduct',
        component: () =>
            import('@/components/Product/CreateProduct.vue')
    },
    {
        path: '/ManageProduct',
        name: 'ManageProduct',
        component: () =>
            import('@/views/ManageProduct')
    },
    {
        path: '/CreateProduct',
        name: 'CreateProduct',
        component: () =>
            import('@/components/Product/CreateProduct.vue')
    },
    {
        path: '/viewProduct',
        name: 'viewProduct',
        component: () =>
            import('@/components/Product/viewProduct.vue')
    },
    {
        path: '/EditProduct',
        name: 'EditProduct',
        component: () =>
            import('@/components/Product/EditProduct.vue')
    },
    {
        path: '/ManageUser',
        name: 'ManageUser',
        component: () =>
            import('@/views/ManageUser')
    },
    {
        path: '/viewUser',
        name: 'viewUser',
        component: () =>
            import('@/components/User/viewUser.vue')
    }, {
        path: '/ManageOrder',
        name: 'ManageOrder',
        component: () =>
            import('@/views/ManageOrder')
    },
    {
        path: '/viewOrder',
        name: 'viewOrder',
        component: () =>
            import('@/components/Order/viewOrder.vue')
    },
    {
        path: '/EditOrder',
        name: 'EditOrder',
        component: () =>
            import('@/components/Order/EditOrder.vue')
    },
    {
        path: '/ManageCompany',
        name: 'ManageCompany',
        component: () =>
            import('@/views/ManageCompany')
    },
    {
        path: '/CreateCompany',
        name: 'CreateCompany',
        component: () =>
            import('@/components/Company/CreateCompany.vue')
    },
    {
        path: '/EditCompany',
        name: 'EditCompany',
        component: () =>
            import('@/components/Company/EditCompany.vue')
    },
    {
        path: '/ViewCompany',
        name: 'ViewCompany',
        component: () =>
            import('@/components/Company/ViewCompany.vue')
    },
    {
        path: '/Dashboard',
        name: 'Dashboard',
        component: () =>
            import('@/views/Dashboard')
    },
    {
        path: '/ManageNews',
        name: 'ManageNews',
        component: () =>
            import('@/views/ManageNews')
    },
    {
        path: '/CreateNews',
        name: 'CreateNews',
        component: () =>
            import('@/components/News/CreateNews.vue')
    },
    {
        path: '/ViewNews',
        name: 'ViewNews',
        component: () =>
            import('@/components/News/ViewNews.vue')
    },
    {
        path: '/EditNews',
        name: 'EditNews',
        component: () =>
            import('@/components/News/EditNews.vue')
    },
    {
        path: '/ManageCoupon',
        name: 'ManageCoupon',
        component: () =>
            import('@/views/ManageCoupon')
    },
    {
        path: '/CreateCoupon',
        name: 'CreateCoupon',
        component: () =>
            import('@/components/Coupon/CreateCoupon.vue')
    },
    {
        path: '/ViewCoupon',
        name: 'ViewCoupon',
        component: () =>
            import('@/components/Coupon/ViewCoupon.vue')
    },
    {
        path: '/EditCoupon',
        name: 'EditCoupon',
        component: () =>
            import('@/components/Coupon/EditCoupon.vue')
    },
    {
        path: '/ManageVoucher',
        name: 'ManageVoucher',
        component: () =>
            import('@/views/ManageVoucher')
    },
    {
        path: '/CreateVoucher',
        name: 'CreateVoucher',
        component: () =>
            import('@/components/Voucher/CreateVoucher.vue')
    },
    {
        path: '/ViewVoucher',
        name: 'ViewVoucher',
        component: () =>
            import('@/components/Voucher/ViewVoucher.vue')
    },
    {
        path: '/EditVoucher',
        name: 'EditVoucher',
        component: () =>
            import('@/components/Voucher/EditVoucher.vue')
    },
    {
        path: '/ManageAnnouncement',
        name: 'ManageAnnouncement',
        component: () =>
            import('@/views/ManageAnnouncement')
    },
    {
        path: '/CreateAnnouncement',
        name: 'CreateAnnouncement',
        component: () =>
            import('@/components/Announcement/CreateAnnouncement.vue')
    },
    {
        path: '/ViewAnnouncement',
        name: 'ViewAnnouncement',
        component: () =>
            import('@/components/Announcement/ViewAnnouncement.vue')
    },
    {
        path: '/EditAnnouncement',
        name: 'EditAnnouncement',
        component: () =>
            import('@/components/Announcement/EditAnnouncement.vue')
    },
    {
        path: '/Report1',
        name: 'Report1',
        component: () =>
            import('@/components/Report/Report1.vue')
    },
    {
        path: '/Report2',
        name: 'Report2',
        component: () =>
            import('@/components/Report/Report2.vue')
    },
    ]
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router