<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #e53935">จัดการบัตรเงินสด</h1>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มบัตรเงินสด</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersNews"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.date`]="{ item }">
              <span>{{ item.voucherStart }} - {{ item.voucherEnd }}</span>
            </template>
            <template v-slot:[`item.activeFlag`]="{ item }">
              <v-chip class="orange lighten-2" v-if="item.activeFlag == true"
                >ใช้งาน</v-chip
              >
              <v-chip
                class="grey lighten-9 white--text"
                v-if="item.activeFlag == false"
                >ไม่ใช้งาน</v-chip
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="ViewVoucher(item)">mdi-eye</v-icon>
                <v-icon @click="UpdateVoucher(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteVoucher(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      headersNews: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อบัตรเงินสด", value: "voucherName", align: "center" },
        { text: "มูลค่า", value: "voucherPrice", align: "center" },
        { text: "จำนวน", value: "voucherAmt", align: "center" },
        { text: "วันที่", value: "date", align: "center" },
        { text: "สถานะ", value: "activeFlag", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
        // activeFlag: true
        // companyId: null
        // count: 1
        // createdAt: "2021-07-13T09:04:00.000Z"
        // eppFlag: false
        // id: 3
        // updatedAt: "2021-07-13T09:04:00.000Z"
        // voucherAmt: 5
        // voucherEnd: "2021-08-10"
        // voucherName: "TEST"
        // voucherPrice: "100"
        // voucherStart: "2021-08-01"
      ],
      items: [],
      search: "",
      count: 0,
    };
  },
  created() {
    this.getAllVoucher();
  },
  methods: {
    async getAllVoucher() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/vouchers`
        // 'http://localhost:8081/categories/'
      );
      console.log("getAllVoucher", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    UpdateVoucher(val) {
      localStorage.setItem("Voucherdata", Encode.encode(val));
      this.$router.push("EditVoucher");
    },
    ViewVoucher(val) {
      localStorage.setItem("Voucherdata", Encode.encode(val));
      this.$router.push("ViewVoucher");
    },
    async DeleteVoucher(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userPoontana"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/vouchers/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllVoucher();
        }
      });
    },
    goToCreate() {
      this.$router.push("createVoucher");
    },
  },
};
</script>