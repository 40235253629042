<template>
  <div>
    <ManageNews />
  </div>
</template>
<script>
import ManageNews from "@/components/News/ManageNews";
export default {
  components: {
    ManageNews,
  },
  created() {
  },
};
</script>c